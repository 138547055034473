<template>
  <div
    class="pr-0 mb-5"
    :class="`${
      $route.name === 'treatments'
        ? 'section-card-container-treatment'
        : 'section-card-container-profile'
    }`"
    v-show="items.length > 0"
  >
    <div class="tw-grid tw-grid-cols-1 xl:tw-grid-cols-3 tw-gap-3">
      <card-prescription
        v-for="item in items"
        :key="item.id"
        @prescription-select="onPrescriptionSelect"
        @prescription-prescribe="onPrescriptionPrescribe"
        @pause="(payload) => onPause(payload, false)"
        @rush-delay="onRushDelay"
        @rush-delay-timer="onRushDelayTimer"
        @update-shipment-date="onUpdateShipmentDate"
        @update-treatment-date="onUpdateTreatmentDate"
        @refetch-single-prescription="cardRefetchSinglePrescription"
        :prescription="item"
      ></card-prescription>
    </div>
    <modal-pause-prescription
      @pause="(payload) => onPause(payload, false)"
      :processing="isProcessing"
      :prescription="selectedPrescription"
    />
    <modal-delete-prescription @delete="onDelete" :processing="isProcessing" />
    <modal-rush-prescription @rush="onRush" :processing="isProcessing" />
    <modal-cancel-order
      @cancel-order="onCancelOrder"
      :processing="isProcessing"
    />
    <modal-resume-prescription
      @resume="onResume"
      @reschedule="onReschedule"
      :processing="isProcessing"
    />
    <modal-cancel-prescription @cancel="onCancel" :processing="isProcessing" />
    <modal-cancel-refund
      @cancel-refund="onCancelRefund"
      :processing="isProcessing"
      :patient-name="selectedPrescription.name"
      :scheduleddatetimestamp="selectedPrescription.scheduleddatetimestamp"
      :prescriptionstatus="selectedPrescription.prescriptionstatus"
    />
    <modal-add-refills
      :patient-name="selectedPrescription.name"
      :scheduleddatetimestamp="selectedPrescription.scheduleddatetimestamp"
      :followupdatestamp="patientInfo.followupdatestamp"
      :subscriptiontype="selectedPrescription.subscriptiontype"
      :processing="isProcessing"
      :prescription="selectedPrescription"
      @activate-refills="onActivateRefills"
      @refill="(payload) => onRefill(payload, false)"
      @hide-add-refills="() => $emit('update:isAddRefillModalOpen', false)"
    />
    <ModalPrescribeEstrogenAlert
      id="prescribe-estrogen-refill-alert"
      :processing="isProcessing"
      @prescribe="(payload) => onRefill(addRefillPayload, true)"
    />
    <ModalPrescribeEstrogenAlert
      id="prescribe-estrogen-pause-alert"
      :processing="isProcessing"
      @prescribe="() => onResume(true)"
    />
    <ModalPrescribeEstrogenAlert
      id="prescribe-estrogen-edit-alert"
      :processing="isProcessing"
      @prescribe="() => $refs.editPrescriptionModal.onEditPrescription(true)"
    />
    <ModalPauseProgesteroneAlert
      :processing="isProcessing"
      @prescribe="(payload) => onPause(pausePayload, true)"
    />
    <ModalPauseDelayTimer
      :processing="isProcessing"
      @rush-delay-timer="onRushDelayTimer"
      @stop-delay="stopDelayTimer"
    />

    <ModalInAddition
      :prescriptions="inAdditionPrescriptions"
      :processing="isProcessing"
      @in-addition="(payload) => onRefill(null, true)"
    />

    <b-modal
      id="duplicate-prescription"
      hide-header
      hide-header-close
      centered
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      size="md"
    >
      <b-row class="mb-4">
        <b-col cols="12">
          <div class="icon-logo"></div>
        </b-col>
      </b-row>
      <div class="tw-float-right tw-w-full lg:tw-w-4/5">
        <div>
          <h2 class="mb-4 heading tw-text-2xl">
            The patient already has a prescription or has a duplicate entry for
            this medication.
          </h2>
        </div>
        <b-row class="mb-4">
          <b-col cols="12">
            <b-button
              block
              pill
              variant="primary"
              @click="$bvModal.hide('duplicate-prescription')"
            >
              <span>Close</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <ModalEditPrescription
      ref="editPrescriptionModal"
      :selected-prescription="selectedPrescription"
      @edit="onEditPrescription"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { isBefore, fromUnixTime } from "date-fns";
import CardPrescription from "@/components/patients/CardPrescription.vue";
import ModalPausePrescription from "@/components/patients/ModalPausePrescription.vue";
import ModalDeletePrescription from "@/components/patients/ModalDeletePrescription.vue";
import ModalRushPrescription from "@/components/patients/ModalRushPrescription.vue";
import ModalCancelOrder from "@/components/patients/ModalCancelOrder.vue";
import ModalResumePrescription from "@/components/patients/ModalResumePrescription.vue";
import ModalCancelPrescription from "@/components/patients/ModalCancelPrescription.vue";
import ModalCancelRefund from "@/components/patients/ModalCancelRefund.vue";
import ModalAddRefills from "@/components/patients/ModalAddRefills.vue";
import ModalPrescribeEstrogenAlert from "@/components/prescription/ModalPrescribeEstrogenAlert.vue";
import ModalPauseProgesteroneAlert from "@/components/prescription/ModalPauseProgesteroneAlert.vue";
import ModalPauseDelayTimer from "@/components/patients/ModalPauseDelayTimer";
import ModalInAddition from "@/components/prescription/ModalInAddition";
import ModalEditPrescription from "@/components/prescription/ModalEditPrescription";

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    isAddRefillModalOpen: {
      type: Boolean,
      default: false,
    },
    selectedRestartPrescription: {
      type: String,
      default: "",
    },
  },
  components: {
    CardPrescription,
    ModalPausePrescription,
    ModalDeletePrescription,
    ModalRushPrescription,
    ModalCancelOrder,
    ModalResumePrescription,
    ModalCancelPrescription,
    ModalCancelRefund,
    ModalAddRefills,
    ModalPrescribeEstrogenAlert,
    ModalPauseProgesteroneAlert,
    ModalPauseDelayTimer,
    ModalInAddition,
    ModalEditPrescription,
  },
  data() {
    return {
      isProcessing: false,
      selectedPrescription: {
        name: "",
        scheduleddatetimestamp: 0,
        prescriptionstatus: "",
      },
      addRefillPayload: null,
      pausePayload: null,

      inAdditionPrescriptions: [],
    };
  },
  watch: {
    isAddRefillModalOpen(val) {
      if (val) {
        this.$bvModal.show("addRefills");
      }
    },
  },
  computed: {
    ...mapGetters({
      patientInfo: "patients/getPatientInfo",
    }),
  },
  methods: {
    cardRefetchSinglePrescription(prescriptionid) {
      this.$emit("refetch-single-prescription", prescriptionid);
    },
    onPrescriptionSelect(prescription) {
      this.selectedPrescription = prescription;
    },
    onPrescriptionPrescribe({ patientid, prescriptionid, done }) {
      this.$store
        .dispatch("prescription/prescribePrescription", {
          patientid,
          prescriptionid,
        })
        .then(async (data) => {
          if (data.refillprescriptionid != undefined) {
            this.$store.dispatch("prescription/refreshPrescriptionOrder", {
              patientid: data.patientid,
              prescriptionid: data.refillprescriptionid,
            });
          }
          this.$emit("refetch-prescriptions", { type: "poll", done });
        });
    },
    async onPause({ isIndefinite, pausedUntilUnix }, force) {
      let payload = {
        prescriptionid: this.selectedPrescription.id,
        ...(force && { data: { force: true } }),
      };

      if (!isIndefinite) {
        payload.data = {
          resumecollection: pausedUntilUnix,
          ...(force && { force: true }),
        };
      }

      try {
        this.isProcessing = true;
        this.pausePayload = { isIndefinite, pausedUntilUnix };
        await this.$store.dispatch("prescription/pausePrescription", payload);
        this.pausePayload = null;
        this.$bvModal.hide("pausePrescription");
        this.$bvModal.hide("resumePrescription");
        this.$bvModal.hide("pause-progesterone-alert");
        this.isProcessing = false;
        this.$emit("refetch-prescriptions");
      } catch (e) {
        console.error(e);
        this.isProcessing = false;

        if (
          e?.message?.includes(
            "Progesterone cannot be paused without pausing estrogen"
          )
        ) {
          this.$bvModal.show("pause-progesterone-alert");
          return;
        }
      }
    },
    async onRushDelayTimer() {
      try {
        this.isProcessing = true;

        await this.$store.dispatch("prescription/rushDelayTimer", {
          prescriptionid: this.selectedPrescription.id,
        });

        this.isProcessing = false;
        this.$bvModal.hide("pauseDelayTimer");
        this.$emit("refetch-prescriptions", { type: "poll" });
      } catch (e) {
        console.error(e);
        this.isProcessing = false;
      }
    },
    async onDelete() {
      try {
        this.isProcessing = true;

        await this.$store.dispatch("prescription/deletePrescription", {
          patientid: this.selectedPrescription.patientid,
          prescriptionid: this.selectedPrescription.id,
        });

        this.$store.dispatch("patients/getPatientInfo", {
          patientId: this.selectedPrescription.patientid,
          isRefetch: true,
        });

        this.isProcessing = false;
        this.$bvModal.hide("deletePrescription");
        this.$emit("refetch-prescriptions", { type: "poll" });
      } catch (e) {
        this.$bvModal.hide("deletePrescription");
        console.error(e);
        this.isProcessing = false;
      }
    },
    async onCancel() {
      try {
        this.isProcessing = true;

        await this.$store.dispatch("prescription/deletePrescription", {
          patientid: this.selectedPrescription.patientid,
          prescriptionid: this.selectedPrescription.id,
        });

        this.$store.dispatch("patients/getPatientInfo", {
          patientId: this.selectedPrescription.patientid,
          isRefetch: true,
        });

        this.isProcessing = false;
        this.$bvModal.hide("cancelPrescription");
        this.$emit("refetch-prescriptions", { type: "poll" });
      } catch (e) {
        this.$bvModal.hide("cancelPrescription");
        console.error(e);
        this.isProcessing = false;
      }
    },
    async onCancelRefund(payload) {
      try {
        this.isProcessing = true;

        await this.$store.dispatch(
          "prescription/cancelRefundPrescriptionOrder",
          {
            prescriptionid: this.selectedPrescription.id,
            cancellationnotes: payload.cancellationnotes,
            cancellationreason: payload.cancellationreason,
            ...(payload.hasOwnProperty("refund") && {
              refund: payload.refund,
            }),
          }
        );

        this.$store.dispatch("patients/getPatientInfo", {
          patientId: this.selectedPrescription.patientid,
          isRefetch: true,
        });

        this.isProcessing = false;
        this.$bvModal.hide("cancelRefund");
        this.$emit("refetch-prescriptions", { type: "poll" });
      } catch (e) {
        this.$bvModal.hide("cancelRefund");
        console.error(e);
        this.isProcessing = false;
      }
    },
    onRush() {
      this.onRushDelay({ prescriptionid: this.selectedPrescription.id });
    },
    async onRushDelay({ prescriptionid }) {
      try {
        this.isProcessing = true;

        await this.$store.dispatch("prescription/bypassDelay", {
          prescriptionid,
        });

        this.isProcessing = false;
        this.$bvModal.hide("rushPrescription");
        setTimeout(() => {
          this.$emit("refetch-prescriptions", { type: "poll" });
        }, 3000);
      } catch (e) {
        this.$bvModal.hide("rushPrescription");
        console.error(e);
        this.isProcessing = false;
      }
    },
    async onCancelOrder() {
      try {
        this.isProcessing = true;

        await this.$store.dispatch("prescription/cancelPendingOrder", {
          prescriptionid: this.selectedPrescription.id,
        });

        this.isProcessing = false;
        this.$bvModal.hide("cancelOrder");
        setTimeout(() => {
          this.$emit("refetch-prescriptions", { type: "poll" });
        }, 3000);
      } catch (e) {
        this.$bvModal.hide("cancelOrder");
        console.error(e);
        this.isProcessing = false;
      }
    },
    async stopDelayTimer() {
      try {
        this.isProcessing = true;

        await this.$store.dispatch("prescription/stopDelay", {
          patientid: this.selectedPrescription.patientid,
          prescriptionid: this.selectedPrescription.id,
        });

        this.$bvModal.hide("pauseDelayTimer");
        this.$emit("refetch-prescriptions", { type: "poll" });
      } catch (e) {
        this.$bvModal.hide("pauseDelayTimer");
        console.error(e);
      } finally {
        this.isProcessing = false;
      }
    },
    async onResume(force) {
      try {
        this.isProcessing = true;

        await this.$store.dispatch("prescription/resumePrescription", {
          prescriptionid: this.selectedPrescription.id,
          payload: force ? { force: true } : null,
        });

        this.isProcessing = false;
        this.$bvModal.hide("resumePrescription");
        this.$bvModal.hide("prescribe-estrogen-pause-alert");
        this.$emit("refetch-prescriptions", { type: "poll" });
      } catch (e) {
        console.error(e);
        if (
          [
            "Estrogen cannot be resumed without resuming progesterone",
            "Estrogen cannot be resumed without progesterone",
          ].includes(e.message)
        ) {
          this.$bvModal.show("prescribe-estrogen-pause-alert");
        } else {
          this.$bvModal.hide("resumePrescription");
        }
        this.isProcessing = false;
      }
    },
    onReschedule() {
      this.$bvModal.show("pausePrescription");
    },
    async onActivateRefills() {
      try {
        this.isProcessing = true;
        await this.$store.dispatch("prescription/payNextInvoice", {
          prescriptionid: this.selectedPrescription.id,
        });
        this.isProcessing = false;
        this.$bvModal.hide("refillactivation");
        this.$bvModal.hide("addRefills");
        await this.$store.dispatch(
          "patients/getPatientFollowupSchedule",
          this.selectedPrescription.patientid
        );
        this.$emit("refetch-prescriptions", { type: "poll" });
      } catch (e) {
        this.isProcessing = false;
        this.$bvModal.hide("refillactivation");
        this.$bvModal.hide("addRefills");
        console.error(e);
        throw e;
      } finally {
        this.$emit("update:isAddRefillModalOpen", false);
      }
    },
    async onRefill(payload, force) {
      try {
        this.isProcessing = true;
        this.addRefillPayload = payload || this.addRefillPayload;
        await this.$store.dispatch("prescription/addRefill", {
          prescriptionid: this.selectedPrescription.id,
          patientid: this.selectedPrescription.patientid,
          payload: {
            ...(payload || this.addRefillPayload),
            ...(force && { force: true }),
          },
        });
        this.isProcessing = false;
        this.addRefillPayload = null;
        this.$bvModal.hide("refillactivation");
        this.$bvModal.hide("addRefills");
        this.$bvModal.hide("inaddition");
        this.$bvModal.hide("prescribe-estrogen-refill-alert");
        await this.$store.dispatch(
          "patients/getPatientFollowupSchedule",
          this.selectedPrescription.patientid
        );
        this.$emit("refetch-prescriptions", { type: "poll" });
        this.$emit("update:isAddRefillModalOpen", false);
      } catch (e) {
        this.isProcessing = false;
        if (
          e?.message?.includes(
            "You are prescribing Estrogen to a patient that has not been prescribed Progesterone."
          )
        ) {
          this.$bvModal.show("prescribe-estrogen-refill-alert");
          return;
        }

        if (e?.message?.includes("Duplicate prescription")) {
          const duplicateActivePrescription = [];
          const duplicateList = e?.response?.data?.body?.duplicate;
          duplicateList.forEach((prescription) => {
            duplicateActivePrescription.push({
              status: prescription.status,
              date: prescription.date,
              prescription: prescription.medicinename,
              dosage: prescription.dosage,
              unit: prescription.unit,
              refills: prescription.refills,
              productid: prescription.productid,
              ...(prescription.status === "New" &&
                duplicateList.some(
                  (item) =>
                    item.productid === prescription.productid &&
                    item.status === "Current"
                ) && {
                  hascurrent: true,
                }),
            });
          });
          this.inAdditionPrescriptions = duplicateActivePrescription;
          this.$bvModal.show("inaddition");
          return;
        }

        if (e?.message?.includes("inactive")) {
          this.$bvToast.toast(
            "The pricing is already inactive. Please write a new prescription.",
            {
              title: "Error",
              variant: "danger",
              toaster: "b-toaster-top-center",
              id: "inactive",
            }
          );
        }

        this.$bvModal.hide("refillactivation");
        this.$bvModal.hide("addRefills");
        this.$bvModal.hide("prescribe-estrogen-refill-alert");
        this.$emit("update:isAddRefillModalOpen", false);
        console.error(e);
        throw e;
      }
    },
    onEditPrescription() {
      this.$emit("refetch-prescriptions", { type: "poll" });
    },
    async onUpdateShipmentDate(payload) {
      await this.$store.dispatch("prescription/updateShipmentDate", {
        prescriptionid: this.selectedPrescription.id,
        patientid: this.selectedPrescription.patientid,
        payload,
      });
      this.$emit("refetch-prescriptions", { type: "poll" });
    },
    async onUpdateTreatmentDate(payload) {
      await this.$store.dispatch("prescription/updateTreatmentDate", {
        prescriptionid: this.selectedPrescription.id,
        patientid: this.selectedPrescription.patientid,
        payload,
      });
      this.$emit("refetch-prescriptions", { type: "poll" });
    },
  },
};
</script>

<style>
@media (min-width: 769px) {
  #inactive__toast_outer {
    top: 60px !important;
  }

  .b-toaster-slot.vue-portal-target:has(#inactive__toast_outer) {
    left: 250px !important;
  }
}

.toast#inactive {
  max-width: none !important;
}
</style>

<style scoped>
.section-card-container-treatment {
  height: auto;
}

.section-card-container-profile {
  height: auto;
}
</style>
